<template>
    <div :class="$vuetify.breakpoint.xs ? 'mx-4 mt-4' : 'mx-8 mt-8'">
        <v-container v-if="order" fluid>
            <v-row>
                <v-col cols="12">
                    <FilterComponent
                        class="ml-n6"
                        @filter="filter"
                        @clearFilters="clearFilters"
                    >
                        <!-- <template #input>
                            <v-col cols="2">
                                <TextField
                                    placeholder="Nombre del producto"
                                    @input="handleProductName"
                                />
                            </v-col>
                        </template> -->
                    </FilterComponent>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    v-for="(data, index) in cardsData"
                    :key="index"
                    class="w-100"
                    cols="12"
                    sm="6"
                    lg="3"
                >
                    <v-card class="pa-3 h-100 left-pink-border" flat>
                        <h2
                            :class="[
                                'font-weight-light',
                                $vuetify.breakpoint.smAndDown
                                    ? 'subtitle-1'
                                    : 'title',
                            ]"
                        >
                            {{ data.title }}
                        </h2>
                        <p
                            :class="
                                $vuetify.breakpoint.smAndDown
                                    ? 'title text-center'
                                    : data.class
                            "
                        >
                            {{ data.value }}
                        </p>
                        <p
                            v-if="data.productData"
                            :class="
                                $vuetify.breakpoint.smAndDown
                                    ? 'title text-center'
                                    : data.class
                            "
                        >
                            {{
                                index === 2
                                    ? data.productData
                                    : "Total acumulado: $" + data.productData
                            }}
                        </p>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="end">
                <!-- <v-col cols="12" md="4">
                    <v-simple-table height="500">
                        <DataTable
                            tableClass="elevation-1"
                            :headers="soldProductsHeaders"
                            :items="soldCategories"
                            hide-default-footer
                            :items-per-page="30"
                        >
                            <template
                                #top="{ pagination, options, updateOptions }"
                            >
                                <v-container fluid class="px-0">
                                    <v-row>
                                        <v-col cols="12" class="d-flex py-2"
                                            ><v-toolbar-title
                                                class="title font-weight-light"
                                            >
                                                Categorias Vendidas
                                            </v-toolbar-title>
                                            <v-divider
                                                vertical
                                                class="ml-5"
                                            ></v-divider>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="px-0 py-1 mb-n4"
                                        >
                                            <v-data-footer
                                                class="d-flex justify-end"
                                                :items-per-page-options="[]"
                                                disable-items-per-page
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                            <template v-slot:[`item.totalIncome`]="{ item }">
                                ${{ item.totalIncome }}
                            </template>
                        </DataTable>
                    </v-simple-table>
                </v-col> -->
                <v-col cols="12" md="6">
                    <v-simple-table height="500">
                        <DataTable
                            tableClass="elevation-1"
                            :headers="soldProductsHeaders"
                            :items="soldProducts"
                            hide-default-footer
                            :items-per-page="30"
                        >
                            <template
                                #top="{ pagination, options, updateOptions }"
                            >
                                <v-container fluid class="px-0">
                                    <v-row>
                                        <v-col cols="12" class="d-flex py-2"
                                            ><v-toolbar-title
                                                class="title font-weight-light"
                                            >
                                                Productos Vendidos
                                            </v-toolbar-title>
                                            <v-divider
                                                vertical
                                                class="ml-5"
                                            ></v-divider>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="px-0 py-1 mb-n4"
                                        >
                                            <v-data-footer
                                                class="d-flex justify-end"
                                                :items-per-page-options="[]"
                                                disable-items-per-page
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                            <template v-slot:[`item.totalIncome`]="{ item }">
                                ${{ item.totalIncome }}
                            </template>
                        </DataTable>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" md="6">
                    <SalesTable
                        :isClearingDates="isClearingDates"
                        :filterStartAndEndDate="filterStartAndEndDate"
                        :productName="productName"
                        :tableFilters="false"
                        :headers="headers"
                        :showExpand="false"
                        :details="false"
                        :dialog="false"
                        :resumen="false"
                        :download="false"
                        :scrollHeight="500"
                    />
                </v-col>
                <v-col cols="12" md="7">
                    <BarChartRevenue
                        :dataToFilterProp="order"
                        :filterStartAndEndDate="filterStartAndEndDate"
                    />
                </v-col>
                <v-col cols="12" md="5">
                    <div>
                        <apexchart
                            height="500"
                            type="donut"
                            :options="chartOptions"
                            :series="donutSeries"
                        ></apexchart>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import SalesTable from "@/components/sales/SalesTable";
import { mapState, mapActions } from "vuex";
import { getSalesComplete } from "../services/sales";
import BarChartRevenue from "@/components/charts/BarChartRevenue.vue";
import FilterComponent from "../components/sales/Filter.vue";
import DataTable from "@/components/vuetify/DataTable";
import TextField from "@/components/vuetify/TextField";

export default {
    components: {
        SalesTable,
        BarChartRevenue,
        FilterComponent,
        DataTable,
        TextField,
    },
    data() {
        return {
            isClearingDates: false,
            cardsData: null,
            order: null,
            filterStartAndEndDate: [],
            productName: "",
            soldProducts: [],
            soldCategories: [],
            headers: [
                { text: "Nro. venta", value: "id", align: "left" },
                { text: "Fecha venta", value: "date_created", align: "left" },
                { text: "Total", value: "total_amount", align: "left" },
            ],
            soldProductsHeaders: [
                { text: "ID", value: "id", align: "left", sortable: false },
                {
                    text: "Nombre",
                    value: "name",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Uds vendidas",
                    value: "totalQuantity",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Total",
                    value: "totalIncome",
                    align: "left",
                    sortable: false,
                },
            ],
            chartOptions: {
                labels: [
                    "Efectivo",
                    "Dinero en cuenta MP",
                    "Débito",
                    "Crédito",
                    "Transferencia",
                ],
                colors: ["#A6EDCB", "#A6CBED", "#EDA6A6", "#EDC6A6", "#AFA6ED"],
            },
            donutSeries: [],
        };
    },
    computed: {
        ...mapState(["userToken"]),
    },

    methods: {
        ...mapActions(["handleLoading"]),
        async filter(startAndEndDate, productName) {
            this.filterStartAndEndDate = startAndEndDate;
            this.productName = productName;
            this.soldProducts = [];
            this.soldCategories = [];
            await this.getAllSales(
                startAndEndDate[0],
                startAndEndDate[1],
                this.productName
            );
            this.isClearingDates = false;
        },
        async clearFilters() {
            this.isClearingDates = true;
            this.filterStartAndEndDate = [];
            this.productName = "";
            await this.getAllSales();
        },
        async getAllSales(from, to, q) {
            try {
                this.handleLoading(true);
                let currentDate = new Date();
                let firstDayOfCurrentMonth = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    1
                );

                let currentDateStr = currentDate.toISOString().slice(0, 10);
                let firstDayOfCurrentMonthStr = firstDayOfCurrentMonth
                    .toISOString()
                    .slice(0, 10);

                const res = await getSalesComplete(
                    this.userToken,
                    from || firstDayOfCurrentMonthStr,
                    to || currentDateStr,
                    q || this.productName
                );
                this.order = res.data;
                const productSales = {};
                const productRevenue = {};
                let mostLucrative = null;
                let maxRevenue = 0;
                const paymentMethodCounts = [
                    { id: 1, name: "Efectivo", count: 0 },
                    { id: 2, name: "Dinero en cuenta MP", count: 0 },
                    { id: 3, name: "Débito", count: 0 },
                    { id: 4, name: "Crédito", count: 0 },
                    { id: 5, name: "Transferencia", count: 0 },
                ];
                let totalProducts = 0;

                this.order.forEach((order) => {
                    const paymentMethodId = order.payment_method.id;
                    const paymentMethod = paymentMethodCounts.find(
                        (method) => method.id === paymentMethodId
                    );
                    if (paymentMethod) {
                        paymentMethod.count++;
                    }
                    this.donutSeries = paymentMethodCounts.map(
                        (method) => method.count
                    );
                    totalProducts += order.products.reduce(
                        (accumulator, product) => {
                            return accumulator + product.pivot.quantity;
                        },
                        0
                    );
                    order.products.forEach((product) => {
                        const productId = product.id;
                        const productName = product.name;
                        const productCategory = product.description;
                        const productQuantity = product.pivot.quantity;
                        const productIncome =
                            parseFloat(product.pivot.unit_price) *
                            productQuantity;

                        const existingProduct = this.soldProducts.find(
                            (p) => p.id === productId
                        );

                        const existingCategory = this.soldCategories.find(
                            (p) => p.name == productCategory
                        );

                        if (existingCategory) {
                            existingCategory.totalQuantity += productQuantity;
                            existingCategory.totalIncome += productIncome;
                        } else {
                            this.soldCategories.push({
                                // Actualmente no trae el ID de categoria
                                // id: this.sold,
                                name: productCategory,
                                totalQuantity: productQuantity,
                                totalIncome: productIncome,
                            });
                        }

                        if (existingProduct) {
                            existingProduct.totalQuantity += productQuantity;
                            existingProduct.totalIncome += productIncome;
                        } else {
                            this.soldProducts.push({
                                id: productId,
                                name: productName,
                                totalQuantity: productQuantity,
                                totalIncome: productIncome,
                            });
                        }
                        this.soldProducts.sort(
                            (a, b) => b.totalQuantity - a.totalQuantity
                        );
                        this.soldCategories.sort(
                            (a, b) => b.totalQuantity - a.totalQuantity
                        );

                        const productRevenueTotal =
                            parseFloat(product.actual_price) *
                            product.pivot.quantity;

                        if (productSales[product.id]) {
                            productSales[product.id].quantity +=
                                product.pivot.quantity;
                        } else {
                            productSales[product.id] = {
                                name: product.name,
                                quantity: product.pivot.quantity,
                            };
                        }
                        if (productRevenue[product.id]) {
                            productRevenue[product.id].revenue +=
                                productRevenueTotal;
                        } else {
                            productRevenue[product.id] = {
                                name: product.name,
                                revenue: productRevenueTotal,
                            };
                        }
                    });
                    for (const productId in productRevenue) {
                        if (productRevenue[productId].revenue > maxRevenue) {
                            maxRevenue = productRevenue[productId].revenue;
                            mostLucrative = productRevenue[productId];
                        }
                    }
                });
                this.cardsData = [
                    {
                        title: "Cantidad de ventas",
                        value: res.data.length
                            .toLocaleString()
                            .replace(/,/g, "."),
                        class: "display-1 mt-8 text-center",
                    },
                    {
                        title: "Total facturado",
                        value:
                            "$" +
                            res.data
                                .reduce((accumulator, currentValue) => {
                                    return Math.floor(
                                        accumulator + currentValue.total_amount
                                    );
                                }, 0)
                                .toLocaleString()
                                .replace(/,/g, "."),
                        class: "display-1 mt-8 text-center",
                    },
                    {
                        title: "Total productos vendidos",
                        class: "display-1 mt-8 text-center",
                        productData: totalProducts,
                    },
                    {
                        title: "Producto mas lucrativo",
                        value: mostLucrative.name,
                        class: "headline text-center",
                        productData: mostLucrative.revenue
                            .toLocaleString()
                            .replace(/,/g, "."),
                    },
                ];
            } catch (error) {
                console.error("error", error);
            } finally {
                this.handleLoading(false);
            }
        },
    },
    async created() {
        await this.getAllSales();
        let currentDate = new Date();
        let firstDayOfCurrentMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
        );

        let currentDateStr = currentDate.toISOString().slice(0, 10);
        let firstDayOfCurrentMonthStr = firstDayOfCurrentMonth
            .toISOString()
            .slice(0, 10);

        this.filterStartAndEndDate = [
            firstDayOfCurrentMonthStr,
            currentDateStr,
        ];
    },
};
</script>
<style scoped lang="scss">
.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}
.left-pink-border {
    border-left: 5px solid $colorPink;
    border-radius: 5px;
}

::v-deep .v-data-footer__pagination {
    font-weight: bold !important;
}
</style>
