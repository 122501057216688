var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$vuetify.breakpoint.xs ? 'mx-4 mt-4' : 'mx-8 mt-8'},[(_vm.order)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('FilterComponent',{staticClass:"ml-n6",on:{"filter":_vm.filter,"clearFilters":_vm.clearFilters}})],1)],1),_c('v-row',_vm._l((_vm.cardsData),function(data,index){return _c('v-col',{key:index,staticClass:"w-100",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-card',{staticClass:"pa-3 h-100 left-pink-border",attrs:{"flat":""}},[_c('h2',{class:[
                            'font-weight-light',
                            _vm.$vuetify.breakpoint.smAndDown
                                ? 'subtitle-1'
                                : 'title',
                        ]},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('p',{class:_vm.$vuetify.breakpoint.smAndDown
                                ? 'title text-center'
                                : data.class},[_vm._v(" "+_vm._s(data.value)+" ")]),(data.productData)?_c('p',{class:_vm.$vuetify.breakpoint.smAndDown
                                ? 'title text-center'
                                : data.class},[_vm._v(" "+_vm._s(index === 2 ? data.productData : "Total acumulado: $" + data.productData)+" ")]):_vm._e()])],1)}),1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-simple-table',{attrs:{"height":"500"}},[_c('DataTable',{attrs:{"tableClass":"elevation-1","headers":_vm.soldProductsHeaders,"items":_vm.soldProducts,"hide-default-footer":"","items-per-page":30},scopedSlots:_vm._u([{key:"top",fn:function({ pagination, options, updateOptions }){return [_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex py-2",attrs:{"cols":"12"}},[_c('v-toolbar-title',{staticClass:"title font-weight-light"},[_vm._v(" Productos Vendidos ")]),_c('v-divider',{staticClass:"ml-5",attrs:{"vertical":""}})],1),_c('v-col',{staticClass:"px-0 py-1 mb-n4",attrs:{"cols":"12"}},[_c('v-data-footer',{staticClass:"d-flex justify-end",attrs:{"items-per-page-options":[],"disable-items-per-page":"","pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})],1)],1)],1)]}},{key:`item.totalIncome`,fn:function({ item }){return [_vm._v(" $"+_vm._s(item.totalIncome)+" ")]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('SalesTable',{attrs:{"isClearingDates":_vm.isClearingDates,"filterStartAndEndDate":_vm.filterStartAndEndDate,"productName":_vm.productName,"tableFilters":false,"headers":_vm.headers,"showExpand":false,"details":false,"dialog":false,"resumen":false,"download":false,"scrollHeight":500}})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('BarChartRevenue',{attrs:{"dataToFilterProp":_vm.order,"filterStartAndEndDate":_vm.filterStartAndEndDate}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('div',[_c('apexchart',{attrs:{"height":"500","type":"donut","options":_vm.chartOptions,"series":_vm.donutSeries}})],1)])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }