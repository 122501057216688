<template>
    <div>
        <apexchart
            height="500"
            type="bar"
            :options="chartOptions"
            :series="series"
        ></apexchart>
    </div>
</template>

<script>
export default {
    name: "BarChartRevenue",
    props: {
        dataToFilterProp: {
            type: Array,
            default: () => [],
        },
        filterStartAndEndDate: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            dataToFilter: this.dataToFilterProp,
            oneDayInMS: 1000 * 60 * 60 * 24,
            chartOptions: {
                chart: {
                    id: "vuechart-example",
                },
                xaxis: {
                    categories: [],
                },
                tooltip: {
                    enabled: true,
                    y: {
                        formatter: function (value) {
                            return (
                                "$" + value.toLocaleString().replace(/,/g, ".")
                            );
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                colors: ["#EDA6AD", "#00FF00", "#0000FF"],
            },
            series: [
                {
                    name: "Total: ",
                    data: [],
                },
            ],
        };
    },
    // created() {
    //     const currentDate = new Date();
    //     const currentMonthStartDate = new Date(
    //         currentDate.getFullYear(),
    //         currentDate.getMonth(),
    //         1
    //     );
    //     const currentMonthEndDate = new Date(
    //         currentDate.getFullYear(),
    //         currentDate.getMonth() + 1,
    //         0
    //     );
    //     const defaultDateRange = [currentMonthStartDate, currentMonthEndDate];
    //     this.formatDataIntoRangesForChart(defaultDateRange);
    // },
    watch: {
        filterStartAndEndDate: {
            deep: true,
            immediate: true,
            handler() {
                if (this.filterStartAndEndDate.length !== 0) {
                    this.formatDataIntoRangesForChart(
                        this.filterStartAndEndDate,
                        this.dataToFilter
                    );
                } else {
                    let currentDate = new Date();
                    let firstDayOfCurrentMonth = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        1
                    );

                    let currentDateStr = currentDate.toISOString().slice(0, 10);
                    let firstDayOfCurrentMonthStr = firstDayOfCurrentMonth
                        .toISOString()
                        .slice(0, 10);

                    this.filterStartAndEndDate = [
                        firstDayOfCurrentMonthStr,
                        currentDateStr,
                    ];
                    this.formatDataIntoRangesForChart(
                        this.filterStartAndEndDate,
                        this.dataToFilter
                    );
                }
            },
        },
        dataToFilterProp: {
            deep: true,
            immediate: true,
            handler(newValue, oldvalue) {
                this.formatDataIntoRangesForChart(
                    this.filterStartAndEndDate,
                    newValue
                );
            },
        },
    },
    methods: {
        formatDataIntoRangesForChart(range, dataToFilter) {
            const startDate = new Date(range[0]);
            const endDate = new Date(range[1]);
            const diffInDays =
                Math.floor(
                    (endDate.getTime() - startDate.getTime()) / this.oneDayInMS
                ) + 1;
            let xDataForChart;

            if (diffInDays <= 31) {
                xDataForChart = this.groupTotalAmounts(
                    diffInDays,
                    [startDate, endDate],
                    this.oneDayInMS,
                    dataToFilter
                );
                return;
            }
            if (diffInDays >= 32 && diffInDays <= 122) {
                xDataForChart = this.groupTotalAmounts(
                    diffInDays / 7,
                    [startDate, endDate],
                    this.oneDayInMS * 7,
                    dataToFilter
                );
                return;
            }
            if (diffInDays >= 123 && diffInDays <= 365) {
                xDataForChart = this.groupTotalAmounts(
                    diffInDays / 30,
                    [startDate, endDate],
                    this.oneDayInMS * 30,
                    dataToFilter
                );
                return;
            }
            if (diffInDays >= 366 && diffInDays <= 2190) {
                xDataForChart = this.groupTotalAmounts(
                    diffInDays / 365,
                    [startDate, endDate],
                    this.oneDayInMS * 365,
                    dataToFilter
                );
                return;
            }
        },
        groupTotalAmounts(
            range,
            startAndEndDate,
            intervalDuration,
            dataToFilter
        ) {
            const startDate = startAndEndDate[0]
                ? new Date(startAndEndDate[0])
                : null;
            const endDate = startAndEndDate[1]
                ? new Date(startAndEndDate[1])
                : null;
            const totalAmounts = {};
            dataToFilter.forEach((obj) => {
                const dateToCheckWithinRange = new Date(obj.created_at);
                if (
                    (!startDate || dateToCheckWithinRange >= startDate) &&
                    (!endDate ||
                        dateToCheckWithinRange <= endDate.getTime() + 86400000)
                ) {
                    const dateKey = dateToCheckWithinRange
                        .toISOString()
                        .split("T")[0];
                    if (totalAmounts[dateKey]) {
                        totalAmounts[dateKey] += obj.total_amount;
                    } else {
                        totalAmounts[dateKey] = obj.total_amount;
                    }
                }
            });

            const intervals = this.getDateIntervalsForXAxis(
                startAndEndDate,
                intervalDuration
            );
            const groups = intervals.map((dateKey) => ({
                x: dateKey,
                y: this.calculateTotalAmountForInterval(
                    dateKey,
                    intervalDuration,
                    totalAmounts
                ),
            }));

            this.series = [
                {
                    name: "Total acumulado: $",
                    data: groups,
                },
            ];

            const chartOptionsCp = { ...this.chartOptions };
            chartOptionsCp.xaxis.categories = intervals;
            this.chartOptions = chartOptionsCp;

            if (intervals.length !== groups.length) {
                console.error("Mismatch between categories and data points");
                return;
            }

            return intervals;
        },

        calculateTotalAmountForInterval(
            intervalDate,
            intervalDuration,
            totalAmounts
        ) {
            let sum = 0;
            const startDate = new Date(intervalDate);
            const endDate = new Date(startDate.getTime() + intervalDuration);

            for (const dateKey in totalAmounts) {
                const date = new Date(dateKey);
                if (date >= startDate && date < endDate) {
                    sum += totalAmounts[dateKey];
                }
            }

            return sum;
        },

        getDateIntervalsForXAxis(startAndEndDateStr, intervalDuration) {
            const startDate = new Date(startAndEndDateStr[0]);
            const endDate = new Date(startAndEndDateStr[1]);

            const intervals = [];
            let currentIntervalStart = new Date(startDate);

            while (currentIntervalStart <= endDate) {
                intervals.push(
                    currentIntervalStart.toISOString().split("T")[0]
                );
                currentIntervalStart = new Date(
                    currentIntervalStart.getTime() + intervalDuration
                );
            }
            return intervals;
        },
    },
};
</script>
